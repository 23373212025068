import config from "../../config"

export const createLocalLink = url => {
	if ( `#` === url ) {
		return null;
	}
	return url ? url.replace(config.apiUrl, '/') : url;
}

export const getMonthName = ( monthIndex ) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
	return months[monthIndex];
}

export const debounce = ( func, wait, immediate ) => {
	var timeout;
	return function () {
	  	var context = this,
			args = arguments;
	  	var later = function () {
			timeout = null;
			if ( !immediate ) func.apply( context, args );
		};
		var callNow = immediate && !timeout;
		clearTimeout( timeout );
	  		timeout = setTimeout( later, wait );
	  		if ( callNow ) func.apply( context, args );
	};
}

export const closeMenu = ( ) => {
	document.documentElement.classList.remove('is-menu-open');
}

// TODO: Return true/false to update state
export const screenScrollHandler = debounce( function () {
	if (typeof window === `undefined`) {
		return;
	}
	var scrollPosY = window.pageYOffset | document.body.scrollTop;
	if ( scrollPosY > 60 ) {
		document.body.classList.add('is-scrolled');
	} else if ( scrollPosY <= 60 ) {
		document.body.classList.remove('is-scrolled');
	}
}, 0.05 );

export const menuHandler = ( ) => {
	document.querySelectorAll('.menu-toggle').forEach(item => {
		item.addEventListener('click', () => {
			if ( document.documentElement.classList.contains('is-menu-open') ) {
				document.documentElement.classList.remove('is-menu-open');
			} else {
				document.documentElement.classList.add('is-menu-open') 
			}	
		});
	});
}
