import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { createLocalLink } from "../../utils"

const Footer = props => {
	const year = new Date().getFullYear();

	const data = useStaticQuery(graphql`
		query footerQuery {
			wpgraphql {
				footerSettings {
					acfSettingsFooter {
						ctaTitle
						ctaText
						ctaLink {
							title
							url
						}
						alternateCtaText
						alternateCtaTitle
						alternateCtaLink {
							title
							url
						}
					}
				}
			}
		}
	`)

	const acfSettingsFooter = data.wpgraphql.footerSettings.acfSettingsFooter;


	const title = ( props.showAlternateCta ) ? acfSettingsFooter.alternateCtaTitle : acfSettingsFooter.ctaTitle;
	const text = ( props.showAlternateCta ) ? acfSettingsFooter.alternateCtaText : acfSettingsFooter.ctaText;
	const linkUrl = ( props.showAlternateCta ) ? acfSettingsFooter.alternateCtaLink.url : acfSettingsFooter.ctaLink.url;
	const linkText = ( props.showAlternateCta ) ? acfSettingsFooter.alternateCtaLink.title : acfSettingsFooter.ctaLink.title;

	return (
		<footer id="colophon" className="site-footer">
			<div className="footer__top">
				<div className="constrained">
					<div className="footer__text">
						<h2>{title}</h2>
						<div dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
					<Link className="btn btn--cta" to={`/${createLocalLink(linkUrl)}`}>{linkText}</Link>
				</div>
			</div>

			<div className="footer__base">
				<div className="constrained">
					&copy; 2015 - {year} Geoff Mortstock. Custom built using Gatsby and WordPress.
				</div>
			</div>
		</footer>
	)
}

export default Footer;