/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import ScrollHeader from "./scrollHeader"
import Footer from "./footer"
import "../css/gutenberg.css"
import "../css/layout.css"

import { screenScrollHandler, menuHandler } from "../../utils"

const Layout = props => {

	// TODO: Update state instead of DOM
	screenScrollHandler();
	useEffect(() => {
		document.addEventListener('scroll', screenScrollHandler);
		menuHandler();
	})	

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			wpgraphql {
				allSettings {
				  	generalSettingsTitle
				}
				headerSettings {
					acfSettingsHeader {
						siteLogo {
							mediaItemUrl
						}
					}
				}
			}
		}
	`)

	const siteTitle = data.wpgraphql.allSettings.generalSettingsTitle;
	const siteLogo = data.wpgraphql.headerSettings.acfSettingsHeader.siteLogo.mediaItemUrl;
	const layoutClass = ( props.contentType === 'post' ) ? 'single': '';
	return (
		<>
			<Header siteTitle={siteTitle} siteLogo={siteLogo} />
			<ScrollHeader siteTitle={siteTitle} siteLogo={siteLogo} />
			<div className={layoutClass}>
				<main>{props.children}</main>
			</div>
			<Footer showAlternateCta={props.showAlternateCta} />
		</>
	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout