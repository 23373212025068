import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import MainMenu from "./mainMenu"

const ScrollHeader = ({ siteTitle, siteLogo }) => (
	<header id="masthead-scrolled" className="site-header site-header-scrolled">
		<div className="site-header__wrap constrained">
			<div className="site-branding">
				<Link to="/" className="custom-logo-link">
					<img src={siteLogo} className="custom-logo" alt={siteTitle} />
				</Link>
				<p className="site-title">
					<Link to="/">
						{siteTitle} Link to home page.
					</Link>
				</p>
			</div>

			<button type="button" id="menu-toggle" className="menu-toggle" aria-label="toggle menu" aria-controls="primary-menu" aria-expanded="false">
				<span className="toggle-close">CLOSE </span>
				<span>MENU</span>
			</button>
			<nav id="site-navigation-scrolled" className="main-navigation main-navigation--scrolled">
				<MainMenu />
			</nav>
		</div>
	</header>
)

ScrollHeader.propTypes = {
	siteTitle: PropTypes.string,
}

ScrollHeader.defaultProps = {
	siteTitle: ``,
}

export default ScrollHeader
