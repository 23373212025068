import React from "react"

const BannerSubtitle = ({ tax, text }) => {
	if ( text === null || text === '' ) {
		return null;
	}
	var fullString = ( tax !== null && tax !== '' && tax !== undefined ) ? tax + ': ' + text : text;

	return (
		<time><p dangerouslySetInnerHTML={{ __html: fullString }}></p></time>
	)
}

export default BannerSubtitle;