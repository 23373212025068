import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { createLocalLink, closeMenu } from "../../utils"

const MAIN_MENU_QUERY = graphql`
	fragment MenuFields on WPGraphql_MenuItem {
		id
		label
		url
	}

	query GET_MENU_ITEMS {
		wpgraphql {
			menuItems(where: {location: PRIMARY}) {
				nodes {
					...MenuFields
					childItems {
						nodes {
							...MenuFields
						}
					}	
				}
			}
		}
	}
`

const renderMenuItem = item => {
	let hasChild = ( item.childItems && item.childItems.nodes.length ) ? true : false;
	let partiallyActiveBool = ( createLocalLink(item.url) === '/' ) ? false : true ;

	return (
		<li className="menu-item" key={item.id}>
			<Link exact="true" to={`/${createLocalLink(item.url)}`} activeClassName="current-menu-item" partiallyActive={partiallyActiveBool} onClick={closeMenu}>{item.label}</Link>
			{hasChild && renderChildMenu(item)}
		</li>
	)
}

const renderChildMenu = (item) => {
	return (
		<ul className="sub-menu">
			{item.childItems.nodes.map(child => renderMenuItem(child))}
		</ul>
	)
}

const MainMenu = props => {
	return (
		<StaticQuery 
			query={MAIN_MENU_QUERY}
			render={({
				wpgraphql: {
					menuItems: { nodes: menu }
				},
			}) => {
				return (
					<ul className="menu primary-menu">
						{menu.map(item => renderMenuItem(item))}
					</ul>
				)
			}}
		/>
	)
}

export default MainMenu;