/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useLocation } from "@reach/router"

import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, titleIsHome, pageImage }) {
	const { site, wpgraphql } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						description
					}
				}
				wpgraphql {
					siteSettings {
						acfSettingsSite {
							twitterUsername
							siteImage {
								sourceUrl(size: LARGE)
							}
						}
					}		  
				}
			}
		`
	)
	const { siteUrl, siteDescription } = site.siteMetadata;

	const { pathname } = useLocation();
	const newPath = pathname.replace('//', '/');
	const pageUrl = siteUrl + newPath;
	const image = ( pageImage ) ? pageImage : wpgraphql.siteSettings.acfSettingsSite.siteImage.sourceUrl;
	const twitterUsername = wpgraphql.siteSettings.acfSettingsSite.twitterUsername;
	const metaDescription = description || siteDescription
	const titlePlaceholder = 'Geoff Mortstock'
	const titleTemplate = ( titleIsHome ) ? title : `${title} | ${titlePlaceholder}`;
	const articleType = ( titleIsHome ) ? 'website' : 'article';
	return (
        <Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={titleTemplate}
		>
          	<meta charSet="utf-8" />
			<meta name="description" content={metaDescription}></meta>

          	<link rel="canonical" href={pageUrl} />
			{pageUrl && <meta property="og:url" content={pageUrl} />}
			<meta property="og:type" content={articleType} />
			{titleTemplate && <meta property="og:title" content={titleTemplate} />}
			{metaDescription && (
				<meta property="og:description" content={metaDescription} />
			)}
			{image && <meta property="og:image" content={image} />}
			<meta name="twitter:card" content="summary_large_image" />
			{twitterUsername && (
				<meta name="twitter:creator" content={twitterUsername} />
			)}
			{titleTemplate && <meta name="twitter:title" content={titleTemplate} />}
			{metaDescription && (
				<meta name="twitter:description" content={metaDescription} />
			)}
			{image && <meta name="twitter:image" content={image} />}
        </Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
