import React from "react"

import BannerSubtitle from "./bannerSubtitle"
import SEO from "../seo"

const PageBanner = ({ title, subtitle, tax, description }) => {

	return (
		<header id="banner" className="banner banner--secondary banner--overlay">
			<SEO title={title} description={description} />

			<div className="constrained banner__content">
				<h1 className="banner__ttl" dangerouslySetInnerHTML={{ __html: title }}>
				</h1>
				<BannerSubtitle tax={tax} text={subtitle} />
			</div>
		</header>
	)
}

export default PageBanner;