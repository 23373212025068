import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import MainMenu from "./mainMenu"

const Header = ({ siteTitle, siteLogo }) => (
	<header id="masthead" className="site-header">
		<div className="site-header__wrap constrained">
			<div className="site-branding">
				<Link to="/" className="custom-logo-link">
					<img src={siteLogo} className="custom-logo" alt={siteTitle} />
				</Link>
				<p className="site-title">
					<Link to="/">
						{siteTitle} Link to home page.
					</Link>
				</p>
			</div>

			<button type="button" id="menu-toggle" className="menu-toggle" aria-label="toggle menu" aria-controls="primary-menu" aria-expanded="false">
				<span className="toggle-close">CLOSE </span>
				<span>MENU</span>
			</button>
			<nav id="site-navigation" className="main-navigation">
				<MainMenu />
			</nav>
		</div>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
